import { MEDIA_NODE_TYPES } from "../constants";
import type { Node } from "../types";

export const isNodeEmpty = (node: Node): boolean => {
  if (
    node.type === "paragraph" &&
    (!node.content || node.content.length === 0)
  ) {
    return true;
  }
  return !!(node.text !== undefined && node.text.trim() === "");
};

export const removeEmptyNodes = (nodes: Node[]): Node[] =>
  nodes
    .filter(node => !isNodeEmpty(node))
    .map(node => {
      if (node.content) {
        return { ...node, content: removeEmptyNodes(node.content) };
      }
      return node;
    });

export const isContentEmpty = (
  content: { content?: Node[] } | null,
): boolean => {
  if (!content || !content.content || !Array.isArray(content.content)) {
    return true;
  }
  return content.content.length === 0;
};

export const isMediaNode = (node: Node): boolean =>
  typeof node.type === "string" && MEDIA_NODE_TYPES.includes(node.type);
