import { some } from "lodash";
import { MAX_CHAR_LENGTH, MEDIA_CHAR_COUNT } from "../constants";
import type { Node, ParsedContent, TruncationState } from "../types";
import { isMediaNode, isNodeEmpty, removeEmptyNodes } from "./nodeUtils";

export const truncateContentWithMediaHandling = (
  content?: Node[] | null,
): ParsedContent => {
  if (!content) {
    return {
      truncatedContent: [],
      mediaContent: null,
      hasCharacterLimitReached: false,
      hasMediaAttachmentsBeforeTruncation: false,
    };
  }

  const state: TruncationState = {
    charCount: 0,
    truncatedContent: [],
    mediaContent: null,
    hasCharacterLimitReached: false,
    textLengthCache: new Map(),
    hasMediaAttachmentsBeforeTruncation: false,
  };

  traverseNodesAndTruncateContent(content, state);

  return {
    truncatedContent: removeEmptyNodes(state.truncatedContent),
    mediaContent: state.mediaContent,
    hasCharacterLimitReached: state.hasCharacterLimitReached,
    hasMediaAttachmentsBeforeTruncation:
      state.hasMediaAttachmentsBeforeTruncation,
  };
};

const setMediaContentIfNoneExists = (
  node: Node,
  state: TruncationState,
): boolean => {
  if (!state.mediaContent) {
    state.mediaContent = node;
    return true;
  }
  return false;
};

const handleNode = (node: Node, state: TruncationState): void => {
  if (isMediaNode(node)) {
    handleMediaNodeContent(node, state);
  } else {
    handleTextNodeContent(node, state);
  }
};

const checkAndSetLimit = (state: TruncationState): void => {
  if (state.charCount >= MAX_CHAR_LENGTH) {
    state.hasCharacterLimitReached = true;
  }
};

const traverseNodesAndTruncateContent = (
  nodes: Node[],
  state: TruncationState,
): boolean =>
  some(nodes, node => {
    if (isNodeEmpty(node)) return false;

    const shouldHandleMedia =
      state.hasCharacterLimitReached && isMediaNode(node);
    const shouldTraverseContent =
      state.hasCharacterLimitReached && Array.isArray(node.content);

    if (shouldHandleMedia && setMediaContentIfNoneExists(node, state)) {
      return true;
    }

    if (!state.hasCharacterLimitReached) {
      handleNode(node, state);
      checkAndSetLimit(state);
    }

    return (
      shouldTraverseContent &&
      traverseNodesAndTruncateContent(node.content ?? [], state)
    );
  });

const handleMediaNodeContent = (node: Node, state: TruncationState): void => {
  if (state.charCount <= MAX_CHAR_LENGTH) {
    state.hasMediaAttachmentsBeforeTruncation = true;
  }

  if (state.charCount + MEDIA_CHAR_COUNT <= MAX_CHAR_LENGTH) {
    state.charCount += MEDIA_CHAR_COUNT;
    state.truncatedContent.push({ ...node });
  } else {
    state.hasCharacterLimitReached = true;
  }
};

const handleTextNodeContent = (node: Node, state: TruncationState): void => {
  const nodeLength = calculateNodeTextLength(node, state);
  const remainingChars = MAX_CHAR_LENGTH - state.charCount;

  if (nodeLength <= remainingChars) {
    state.charCount += nodeLength;
    state.truncatedContent.push({ ...node });
  } else {
    truncateTextNodeContent(node, remainingChars, state);
    state.hasCharacterLimitReached = true;
  }
};

const truncateTextNodeContent = (
  node: Node,
  remainingChars: number,
  state: TruncationState,
): void => {
  const nodeCopy = { ...node };
  if (typeof node.text === "string") {
    nodeCopy.text = node.text.slice(0, remainingChars).trim();
    if (nodeCopy.text) {
      if (remainingChars < node.text.length) {
        nodeCopy.text += "...";
      }
      state.truncatedContent.push(nodeCopy);
    }
  } else if (node.content && Array.isArray(node.content)) {
    nodeCopy.content = [];
    state.truncatedContent.push(nodeCopy);
    traverseNodesAndTruncateContent(node.content, state);
  }
};

const calculateNodeTextLength = (
  node: Node,
  state: TruncationState,
): number => {
  if (state.textLengthCache.has(node)) {
    return state.textLengthCache.get(node) ?? 0;
  }

  let length = 0;
  if (typeof node.text === "string") {
    length = node.text.length;
  } else if (node.content && Array.isArray(node.content)) {
    length = node.content.reduce(
      (sum, childNode) => sum + calculateNodeTextLength(childNode, state),
      0,
    );
  }

  state.textLengthCache.set(node, length);
  return length;
};
